<template>
  <div class="empty__container">
    <slot class="icon__container"></slot>
    <p class="hint">{{ hint }}</p>
    <div class="goBtn" @click="go">{{ btnText }}</div>
  </div>
</template>
<script >
export default ({
  name: 'c_empty',
  props: {
    hint: {
      default: '',
      type: String,
    },
    btnText: {
      default: '',
      type: String,
    },
    link: {
      default: '',
      type: String,
    },
    clickAction: {
      default: undefined,
      type: Function,
    },
  },
  methods: {
    go() {
      if (this.link) {
        this.$router.push({
          path: this.link,
        });
      } else if (this.clickAction) {
        this.clickAction();
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.empty__container {
  @include flex-box;
  flex-direction: column;
  align-items: center;

  .hint {
    font-size: 28px;
    color: #bbb;
  }

  .goBtn {
    line-height: 50px;
    margin-top: 20px;
    background-color: #000;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    min-width: 300px;
  }

  font-size: 28px;
}
</style>
