<template>
  <a @click="goJump(member.info.uid)" v-if="member" class="friend-list__item__wrapper-link" :class="{
    'long-pressed': longPressShow
  }">

    <div class="friend-list__item" v-long-press="queue === -1 ? () => { } : onLongPress">
      <div class="item__option">
        <template v-if="queue === -1">
          <svg-icon class="sp" icon-class="icon-md-star"></svg-icon>
        </template>
        <template v-else>
          {{ queue + 1 }}
        </template>
      </div>
      <div class="item__option">
        <div class="option__header">
          <img :src="member.info.headimgurl" alt="" />
        </div>
        <div class="option__name">
          {{ member.comment ? member.comment : member.info.nickname }}
        </div>
      </div>
      <div class="item__option">
        <strong>{{ member.timelinepostcount }}</strong>
      </div>
      <div class="item__option" :class="{
        'has-new': Number(member.topicnoviewcount) > 0
      }">
        <svg-icon icon-class="share-star" class="reply-new-notice"
          v-if="!member.isBlock && Number(member.topicnoviewcount) > 0"></svg-icon>
        <strong>{{ member.topicpostcount }}</strong>
      </div>
      <svg-icon v-if="member.isBlock" class="unbell" icon-class="icon-unbell"></svg-icon>
      <div class="long-press-container" v-show="longPressShow">
        <div class="press-item" @click.stop="switchMuted" :data-uid="member.info.uid">{{ member.isBlock ? "恢复提示" : "不再提示"
        }}
        </div>
      </div>
    </div>

    <div class="long-press-mask" @click.stop="onCloseLongPress" v-show="longPressShow"></div>

    <!-- <van-swipe-cell stop-propagation :before-close="onSwitchHint" :data-uid="member.info.uid">
      <div class="friend-list__item">
        <div class="item__option">
          <template v-if="queue === -1">
            <svg-icon class="sp" icon-class="icon-md-star"></svg-icon>
          </template>
          <template v-else>
            {{ queue + 1 }}
          </template>
        </div>
        <div class="item__option">
          <div class="option__header">
            <img :src="member.info.headimgurl" alt="" />
          </div>
          <div class="option__name">
            {{ member.comment ? member.comment : member.info.nickname }}
          </div>
        </div>
        <div class="item__option">
          <strong>{{ member.timelinepostcount }}</strong>
        </div>
        <div class="item__option" :class="{
          'has-new': Number(member.topicnoviewcount) > 0
        }">
          <svg-icon icon-class="share-star" class="reply-new-notice"
            v-if="!member.isBlock && Number(member.topicnoviewcount) > 0"></svg-icon>
          <strong>{{ member.topicpostcount }}</strong>
        </div>
      </div>
      <template #right>
        <van-button class="swipe-cell-button" :class="[member.isBlock ? 'black' : 'red']">
          {{ member.isBlock ? "恢复提示" : "不再提示" }}
        </van-button>
      </template>
    </van-swipe-cell> -->
  </a>
</template>

<script>
export default {
  name: 'c_friend-list-item',
  props: {
    member: {
      type: Object,
      default: null,
    },
    queue: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      longPressShow: false,
    };
  },
  methods: {
    goJump(uid) {
      this.$router.push({
        path: `/friend/${uid}`,
      });
    },
    onSwitchHint({ position, instance }) {
      const that = this;
      if (position === 'right') {
        that.requesting = true;
        const { uid } = instance.$el.dataset;
        that.$request
          .get(`api/user/topic/topic-hint-switch-total/${uid}`)
          .then((res) => {
            console.log(res);
            that.member.isBlock = res.data.block;
          })
          .finally(() => {
            that.requesting = false;
            instance.close();
          });
      } else {
        instance.close();
      }
    },
    switchMuted(e) {
      e.stopPropagation();
      const that = this;
      that.requesting = true;
      const { uid } = e.currentTarget.dataset;
      that.$request
        .get(`api/user/topic/topic-hint-switch-total/${uid}`)
        .then((res) => {
          console.log(res);
          that.member.isBlock = res.data.block;
        })
        .finally(() => {
          that.requesting = false;
          that.onCloseLongPress();
        });
    },
    onLongPress() {
      this.longPressShow = true;
    },
    onCloseLongPress() {
      this.longPressShow = false;
    },
  },
};
</script>

<style scoped lang="scss">
.friend-list__item__wrapper-link {
  &.long-pressed {
    background-color: #f0f0f0;
  }
}

.long-press-mask {
  @include long-press-mask;
}

.friend-list__item {
  .item__option {
    @include selectNone;
  }
}

.unbell {
  position: absolute;
  top: 50%;
  left: 560px;
  transform: translate(0, -50%);
  width: 30px;
  height: 30px;
}
</style>
