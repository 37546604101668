<template>
  <div class="friends-list-container">
    <!-- 搜索 -->
    <div class="part__search-container">
      <!-- <van-search
        v-model="keyword"
        placeholder="搜索"
        @search="onSearch"
        @cancel="onCancel"
      ></van-search> -->
      <van-search v-model="keyword" placeholder="搜索"></van-search>
    </div>
    <div class="friends-list-header">
      <div class="header__item">排行</div>
      <div class="header__item">亲友</div>
      <div class="header__item">时光↓</div>
      <div class="header__item">分享</div>
    </div>
    <div class="friends__list-main-body">
      <friend-item v-if="userItem.info" :member="userItem" :queue="-1"></friend-item>
      <van-list
        class="user-friends__list"
        v-model="requesting"
        :finished="finished"
        @load="getFriends"
      >
        <friend-item
          v-for="(member, index) in friends"
          :key="member.info.uid"
          :member="member"
          :queue="index"
        ></friend-item>
      </van-list>
    </div>
    <!-- 空白状态 -->
    <empty
      style="margin-top: 180px"
      v-if="isEmpty"
      hint="添加亲友之后会出现在这里"
      btn-text="邀请亲友"
      link="/my/code"
    ></empty>
    <!-- 标语 -->
    <slogan v-else text="没有人是一座孤岛"></slogan>
    <add-bt
      v-if="false"
      icon="icon-plus-red"
      theme-color="red"
      @click.native="onCreateTimeline"
    ></add-bt>
    <!-- 个人/小组切换 -->
    <page-switch></page-switch>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import friendItem from './_item.vue';
import addBt from '../common/_add-bt.vue';
import slogan from '../common/_slogan.vue';
import pageSwitch from '../common/_switch.vue';
import empty from '../common/_empty.vue';

export default {
  name: 'friends-list',
  components: {
    friendItem,
    addBt,
    slogan,
    pageSwitch,
    empty,
  },
  data() {
    return {
      keyword: '',
      requesting: false,
      lists: [],
      friends: [],
      finished: false,
      userItem: {
        comment: '',
        info: null,
        isBlock: false,
        timelinepostcount: 0,
        topicnoviewcount: 0,
        topicpostcount: 0,
        user_relation: 1,
      },
      isEmpty: false,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    keyword(val) {
      if (val) {
        this.onSearch();
      } else {
        this.onCancel();
      }
    },
    user: {
      handler(val) {
        if (val) {
          this.userItem.info = val;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getUserCounts();
  },
  methods: {
    ...mapMutations(['SET_FRIENDS_POS_INFO']),
    onCreateTimeline() {
      this.$router.push({
        path: '/timeline/edit',
      });
    },
    getFriends() {
      const that = this;
      that.isEmpty = false;
      that.$request
        .get('api/user/friend', {
          count: true,
        })
        .then((res) => {
          that.lists = res.data;
          that.finished = true;
          that.friends = that.lists;
          // 缓存用户排名信息
          if (that.lists.length > 0) {
            that.SET_FRIENDS_POS_INFO(that.lists);
          } else {
            that.isEmpty = true;
          }
        })
        .finally(() => {
          that.requesting = false;
        });
    },
    onSearch() {
      const that = this;
      if (that.keyword) {
        that.friends = that.lists.filter((item) => {
          const nickname = item.info.nickname.match(that.keyword);
          const comment = item.comment.match(that.keyword);
          return !(nickname === null && comment === null);
        });
      } else {
        that.friends = that.lists;
      }
    },
    onCancel() {
      const that = this;
      that.friends = that.lists;
    },
    getUserCounts() {
      const timelineCount = this.$request.get('api/user/account/timeline-count');
      const shareCount = this.$request.get('api/user/account/topic-count');

      Promise.all([timelineCount, shareCount]).then((res) => {
        const [timeline, topic] = res;
        this.userItem.timelinepostcount = timeline.data.count;
        this.userItem.topicpostcount = topic.data.count;
      });
    },
    onLongPress() {
      console.log('触发长按');
    },
  },
};
</script>
