<template>
  <div class="app-slogan-container">
    <p>
      <span v-if="sign">{{ sign }} </span>{{ text }}<span v-if="sign"> {{ sign }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'c__slogan',
  props: {
    text: {
      type: String,
      default: '',
    },
    sign: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.app-slogan-container {
  width: 100%;
  padding-top: 200px;
  padding-bottom: 240px;

  p {
    font-size: 28px;
    line-height: 1;
    color: #bbb;
    text-align: center;

    span {
      color: #000;
    }
  }
}
</style>
