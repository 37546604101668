<template>
    <div class="page__switch-container">
        <div class="inner">
            <router-link class="link" to="/friends" tag="div">个人</router-link>
            <router-link class="link" to="/groups" tag="div">小组</router-link>
        </div>
    </div>
</template>
<script>
export default ({
  name: 'c_switch',
});
</script>
<style lang="scss" scoped>
.page__switch-container {

    position: fixed;
    bottom: 168px;
    left: 50%;
    transform: translateX(-50%);

    @include saveAreaBottom;

    .inner {
        @include flex-box;
        font-size: 28px;
        border-radius: 10px;
        overflow: hidden;
        background-color: #6C6C6C;
        color: #fff;
    }

    .link {
        width: 150px;
        height: 50px;
        text-align: center;
        line-height: 50px;

        &.router-link-active {
            background-color: #000;
            border-radius: 10px;
        }
    }
}
</style>
