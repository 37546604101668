import { render, staticRenderFns } from "./_slogan.vue?vue&type=template&id=278f0d9b&scoped=true&"
import script from "./_slogan.vue?vue&type=script&lang=js&"
export * from "./_slogan.vue?vue&type=script&lang=js&"
import style0 from "./_slogan.vue?vue&type=style&index=0&id=278f0d9b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "278f0d9b",
  null
  
)

export default component.exports